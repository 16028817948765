<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" class="textSection" v-bind:class="mobile ? 'firstSectionMobile' : 'firstSection'" no-gutters>
      {{ $t('firstSectionText')}}
    </v-row>
    <div v-bind:class="mobile ? 'secondSectionMobile' : 'secondSection'" >
      <v-row justify="center" v-bind:class="mobile ? 'textSectionMobile' : 'textSection'" no-gutters>
        <v-col v-bind:cols="mobile ? 12 : 3">
          <v-img
              :src="require('@/assets/picto/picto2.svg')"
              contain
              height="100"
              v-bind:class="mobile ? 'firstImgSecondSectionMobile' : 'firstImgSecondSection'"
          />
        </v-col>
        <v-col v-bind:cols="mobile ? 12 : 6">
          <p v-html="$t('secondSectionText')"></p>
        </v-col>
        <v-col cols="3" v-if="!mobile">
          <v-img
              :src="require('@/assets/main/logoVitae.png')"
              contain
              height="125"
              class="secondImgSecondSection"
          />
        </v-col>
        <v-col cols="12">
          <v-icon
              class="iconEndSection"
              size="100"
              color="#FFFFFF"
          >
            mdi-chevron-down
          </v-icon>
        </v-col>
      </v-row>
    </div>
    <div v-bind:class="mobile ? 'thirdSectionMobile' : 'thirdSection'">
      <v-row justify="center" v-bind:class="mobile ? 'textThirdSectionMobile' : 'textThirdSection'" no-gutters>
        <v-col cols="1" v-if="!mobile"></v-col>
        <v-col v-bind:cols="mobile ? 12 : 4" v-bind:class="mobile ? '' : 'contentLeftThirdSection'">
          <p v-html="$t('thirdSectionTextLeftPart1')"></p>
          <p v-html="$t('thirdSectionTextLeftPart2')"></p>
        </v-col>
        <v-col cols="1" v-if="!mobile"></v-col>
        <v-col v-bind:cols="mobile ? 12 : 4"  v-bind:class="mobile ? '' : 'contentRightThirdSection'">
          <p v-html="$t('thirdSectionTextRightPart1')"></p>
          <v-btn
              outlined
              rounded
              width="100%"
              height="60"
              color="#FFFFFF"
              v-bind:class="mobile ? 'btnThirdSectionMobile': 'btnThirdSection'"
              @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
              v-html="$t('thirdSectionTextRightPart2')"
          >
          </v-btn>
        </v-col>
        <v-col cols="1" v-if="!mobile"></v-col>
        <v-col cols="12" style="text-align: center">
          <v-icon
              class="iconEndThirdSection"
              size="100"
              color="#FFFFFF"
          >
            mdi-chevron-down
          </v-icon>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'secondTitlePresentationMobile' : 'secondTitlePresentation'">{{ $t('secondTitle1') }}</h1>
    </v-row>
    <v-row justify="center" no-gutters>
      <h3 v-bind:class="mobile ? 'subTitlePresentationMobile' : 'subTitlePresentation'">{{ $t('subTitle1') }}</h3>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col cols="2" v-if="mobile">
        <v-img
            :src="require('@/assets/picto/separator4.svg')"
            contain
            height="1050"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 5" v-bind:class="mobile ? 'fourSectionLeftMobile' : 'fourSectionLeft'">
        <v-img
            v-if="mobile"
            class="imgMobile"
            :src="require('@/assets/picto/pictoPresentation2.svg')"
            contain
            height="45"
        />
        <h3 v-bind:class="mobile ? 'subTitleFourSectionMobile' : 'subTitleFourSection'">{{ $t('subtitleFourSectionLeft1') }}</h3> <br>
        <br v-if="$i18n.locale !== 'fr'">
        <div v-bind:class="mobile ? 'textFourSectionMobile' : 'textFourSectionLeft'">
          {{ $t('textFourSectionLeft1') }}
          <v-img
              v-if="!mobile"
              class="fourSectionImgLeft"
              :src="require('@/assets/picto/pictoPresentation1.svg')"
              contain
              height="100"
          />
        </div>
        <br v-if="mobile">
        <v-img
            v-if="mobile"
            class="imgMobile"
            :src="require('@/assets/picto/pictoPresentation4.svg')"
            contain
            height="45"
        />
        <h3 v-bind:class="mobile ? 'subTitleFourSectionMobile' : 'subTitleFourSection'">{{ $t('subtitleFourSectionLeft2') }}</h3> <br>
        <div v-bind:class="mobile ? 'textFourSectionMobile' : 'textFourSectionLeft'">
          {{ $t('textFourSectionLeft2') }}
          <v-img
              v-if="!mobile"
              class="fourSectionImgLeftLast"
              :src="require('@/assets/picto/pictoPresentation3.svg')"
              contain
              height="100"
          />
        </div>
        <v-img
            v-if="mobile"
            class="imgMobile"
            :src="require('@/assets/picto/pictoPresentation1.svg')"
            contain
            height="45"
        />
        <h3 v-if="mobile" v-bind:class="mobile ? 'subTitleFourSectionMobile' : 'subTitleFourSection'">{{ $t('subtitleFourSectionRight1') }}</h3> <br>
        <div v-if="mobile" class="textFourSectionMobile">
          {{ $t('textFourSectionRight1') }}
        </div>
        <v-img
            v-if="mobile"
            class="imgMobile"
            :src="require('@/assets/picto/pictoPresentation3.svg')"
            contain
            height="45"
        />
        <h3 v-if="mobile" v-bind:class="mobile ? 'subTitleFourSectionMobile' : 'subTitleFourSection'">{{ $t('subtitleFourSectionRight2') }}</h3> <br>
        <div v-if="mobile" style="font-size: 14px; padding-right: 15px;">
          {{ $t('textFourSectionRight2') }}
        </div>
      </v-col>
      <v-col cols="2" v-if="!mobile">
        <v-img
            :src="require('@/assets/picto/separator4.svg')"
            contain
            height="850"
        />
      </v-col>
      <v-col cols="5" v-if="!mobile" v-bind:class="mobile ? '' : 'fourSectionRight'">
        <v-img
            class="fourSectionImgRightFirst"
            :src="require('@/assets/picto/pictoPresentation2.svg')"
            contain
            height="100"
        />
        <h3 class="subTitleFourSection">{{ $t('subtitleFourSectionRight1') }}</h3> <br>
        <div class="textFourSectionRight">
          {{ $t('textFourSectionRight1') }}
          <v-img
              class="fourSectionImgRight"
              :src="require('@/assets/picto/pictoPresentation4.svg')"
              contain
              height="100"
          />
        </div>
        <br v-if="$i18n.locale !== 'fr'">
        <h3 class="subTitleFourSection">{{ $t('subtitleFourSectionRight2') }}</h3> <br>
        <div class="textFourSectionRight">
          {{ $t('textFourSectionRight2') }}
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'secondTitlePresentationMobile' : 'secondTitlePresentation'">{{ $t('secondTitle2') }}</h1>
    </v-row>
    <v-row justify="center" no-gutters>
      <h3 v-bind:class="mobile ? 'subTitlePresentationMobile' : 'subTitlePresentation'">{{ $t('subTitle2') }}</h3>
    </v-row>
    <v-row justify="space-around" v-bind:class="mobile ? 'lastSectionMobile' : 'lastSection'" no-gutters>
      <v-col v-bind:style="mobile ? 'padding-top: 35px;' : ''" v-bind:cols="mobile ? 6 : 3" v-for="(type, index) in sectionsFirstLine"
             :key="index">
        <v-img
            :src="type.image"
            contain
            v-bind:height="mobile ? 125 : 175"
        />
        <br>
        {{ $t(type.text) }}
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'lastSectionMobile' : 'lastSection'" no-gutters>
      <v-col  v-bind:cols="mobile ? 6 : 3" v-for="(type, index) in sectionsSecondLine"
             :key="index">
        <v-img
            :src="type.image"
            contain
            v-bind:height="mobile ? 125 : 175"
        />
        <br>
        {{ $t(type.text) }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PresentationVitaeComp',
  props: ['mobile'],
  data: () => ({
    sectionsFirstLine: [
      {
        image: require('@/assets/vitaeImg/section7.png'),
        text: 'lastSectionText1',
      },
      {
        image: require('@/assets/vitaeImg/section12.png'),
        text: "lastSectionText2",
      },
      {
        image: require('@/assets/vitaeImg/section11.png'),
        text: 'lastSectionText3',
      },
      {
        image: require('@/assets/vitaeImg/section10.png'),
        text: 'lastSectionText4',
      }
    ],
    sectionsSecondLine: [
      {
        image: require('@/assets/vitaeImg/section9.png'),
        text: 'lastSectionText5',
      },
      {
        image: require('@/assets/vitaeImg/section8.png'),
        text: "lastSectionText6",
      }
    ]
  }),
}
</script>

<i18n>
{
  "en": {
    "mainTitle": "Presentation of the association",
    "secondTitle1": "The Vitaé sport santé approach",
    "subTitle1": "« The quality of our interventions is based on 4 important aspects »",
    "secondTitle2": "Supported audiences",
    "subTitle2": "Anyone wishing to be accompanied in an adapted physical activity!",
    "firstSectionText": "« Promote and develop the health of the population, through the practice of adapted physical activities and awareness of healthy living »",
    "secondSectionText": "<strong> Vitaé sport santé </strong> is a <strong> association </strong> law 1901 composed of a <strong> office of volunteers and a team of qualified professionals </strong> in the field of sport and health.",
    "thirdSectionTextLeftPart1": "Since 2021, our structure has been <strong> recognized as a health sports center by the Ministry of Sport and the Ministry of Solidarity and Health </strong>. Our main mission is to prevent, maintain or improve the physical, social and mental well-being of populations through the practice of an adapted physical activity close to where they live.",
    "thirdSectionTextLeftPart2": "But this system is above all <strong> a network, made up of health and social actors </strong> who direct people to sports actors offering adapted physical activities.",
    "thirdSectionTextRightPart1": "Let's build <strong> together </strong> a territorial sport health well-being competence in the <strong> Alpes-Maritimes </stong>",
    "thirdSectionTextRightPart2": "Join the network of <br> Vitaé Maison du sport santé!",
    "subtitleFourSectionLeft1": "Evaluation /Adapted balance sheet",
    "textFourSectionLeft1": "We assess our users based on scientifically validated tests in order to have a precise vision of their evolution.",
    "subtitleFourSectionLeft2": "A holistic approach to health",
    "textFourSectionLeft2": "We use a biopsychosocial approach in order to act positively as much on physical health, as on psychological and social health.",
    "subtitleFourSectionRight1": "Personalized follow-up",
    "textFourSectionRight1": "Each person works on personal objectives defined upstream of the interventions. The goal is to adapt our care to people's expectations and needs.",
    "subtitleFourSectionRight2": "A positive impact on daily life",
    "textFourSectionRight2": "Our interventions always aim to improve people's autonomy and quality of life.",
    "lastSectionText1": "Autonomous or dependent seniors",
    "lastSectionText2": "People with chronic disease (Parkinson's, Alzheimer's, diabetic, cardiovascular disease, etc.)",
    "lastSectionText3": "People with intellectual disabilities / or suffering from a mental disorder",
    "lastSectionText4": "People with motor problems",
    "lastSectionText5": "People working in business",
    "lastSectionText6": "Overweight or obese people"
  },
  "fr": {
    "mainTitle": "Présentation de l'association",
    "secondTitle1": "L'approche Vitaé sport santé",
    "subTitle1": "« La qualité de nos interventions repose sur 4 aspects importants »",
    "secondTitle2": "Publics pris en charge",
    "subTitle2": "Toute personnes souhaitant être accompagnée dans une activité physique adaptée !",
    "firstSectionText": "« Promouvoir et développer la santé de la population, au travers de la pratique d'activités physiques adaptées et de la sensibilisation à une bonne hygiènne de vie »",
    "secondSectionText": "<strong>Vitaé sport santé</strong> est une <strong>association</strong> loi 1901 composée d'un <strong>bureau de bénévoles et d'une équipe de professionnels diplômés </strong> dans le domaine du sport et la santé.",
    "thirdSectionTextLeftPart1": "Depuis 2021, notre structure est <strong>reconnue Maison du Sport Santé par le ministère des Sports et le ministère de la Solidarité et de la Santé </strong>. Notre mission principale est de prévenir, maintenir ou améliorer le bien-être physique, social et mental des populations grâce à la pratique d’une activité physique adaptée proche de leur lieu de vie.",
    "thirdSectionTextLeftPart2": "Mais ce dispositif est avant tout <strong> un réseau, constitué d’acteurs de la santé et du social </strong> qui orientent des personnes vers des acteurs du sport proposant des activités physiques adaptées.",
    "thirdSectionTextRightPart1": "Construisons <strong> ensemble </strong> une compétence sport santé bien être territoriale dans les <strong> Alpes-Maritimes </stong>",
    "thirdSectionTextRightPart2": "Rejoindre le réseau de la <br> Maison du sport santé Vitaé !",
    "subtitleFourSectionLeft1": "Évaluation /bilan adapté",
    "textFourSectionLeft1": "Nous évaluons nos usagers à partir de tests scientifiquement validés afin d’avoir une vision précise de leur évolution.",
    "subtitleFourSectionLeft2": "Une approche globale de la santé",
    "textFourSectionLeft2": "Nous utilisons une approche biopsychosociale afin d’agir de manière positive autant sur la santé physique, que sur la santé psychologique et sociale.",
    "subtitleFourSectionRight1": "Suivi personnalisé",
    "textFourSectionRight1": "Chaque personne travaille sur des objectifs personnels définis en amont des interventions. Le but étant d’adapter nos prises en charge aux attentes et besoins des personnes.",
    "subtitleFourSectionRight2": "Un impact positif sur le quotidien",
    "textFourSectionRight2": "Nos interventions ont toujours pour objectifs d’améliorer l’autonomie et la qualité de vie des personnes.",
    "lastSectionText1": "Personnes âgées autonomes ou dépendantes",
    "lastSectionText2": "Personnes atteintes d’une maladie chronique (Parkinson, Alzheimer, diabétique, maladie cardio-vasculaire...)",
    "lastSectionText3": "Personnes déficientes intellectuelles /ou atteintes d’un trouble psychique",
    "lastSectionText4": "Personnes ayant des problèmes moteurs",
    "lastSectionText5": "Personnes actives en entreprise",
    "lastSectionText6": "Personnes en surpoids ou obèses"
  }
}
</i18n>

<style scoped>

.textSection {
  text-align: center;
  color: #1579A7;
  font-size: 20px;
}

.textSectionMobile {
  text-align: center;
  color: #1579A7;
  font-size: 16px;
}

.firstSectionMobile {
  font-size: 16px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 80px;
}

.secondSectionMobile {
  padding-top: 70px;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #D5EEF4;
}

.secondSection {
  padding-top: 150px;
  background-color: #D5EEF4;
}

.firstImgSecondSectionMobile {
  margin-bottom: 20px;
}

@media screen and (max-width: 1500px){
  .firstImgSecondSection {
    margin-top: -10px;
  }
  .firstSection {
    padding-right: 290px;
    padding-left: 290px;
    padding-bottom: 100px;
  }
  .secondImgSecondSection {
    margin-top: -25px;
  }
  .fourSectionImgLeft {
    margin-top: 140px;
    margin-bottom: 135px;
    margin-left: 150px;
  }

  .fourSectionImgLeftLast {
    margin-top: 125px;
    margin-left: 150px;
  }

  .fourSectionImgRightFirst {
    margin-top: 50px;
    margin-right: 450px;
    margin-bottom: 110px;
  }

  .fourSectionImgRight {
    margin-top: 120px;
    margin-right: 150px;
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1500px){
  .firstImgSecondSection {
    margin-left: 250px;
    margin-top: -10px;
  }
  .firstSection {
    padding-right: 300px;
    padding-left: 300px;
    padding-bottom: 100px;
  }
  .secondImgSecondSection {
    margin-right: 250px;
    margin-top: -25px;
  }
  .fourSectionImgLeft {
    margin-top: 140px;
    margin-bottom: 135px;
    margin-left: 300px;
  }

  .fourSectionImgLeftLast {
    margin-top: 125px;
    margin-left: 300px;
  }

  .fourSectionImgRightFirst {
    margin-top: 50px;
    margin-right: 600px;
    margin-bottom: 110px;
  }

  .fourSectionImgRight {
    margin-top: 120px;
    margin-right: 300px;
    margin-bottom: 120px;
  }
}

.thirdSection {
  padding-top: 100px;
  background-color: #1579A7;
}

.thirdSectionMobile {
  padding-top: 80px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #1579A7;
}

.textThirdSection {
  text-align: center;
  color: #FFFFFF;
  font-size: 18px;
}

.textThirdSectionMobile {
  text-align: left;
  color: #FFFFFF;
  font-size: 16px;
}

.contentLeftThirdSection {
  text-align: left;
  padding-left: 20px;
}

.contentRightThirdSection {
  text-align: start;
  padding-left: 50px;
  padding-right: 150px;
}

.btnThirdSection {
  letter-spacing: 1px;
  line-height: 20px !important;
  margin-top: 50px;
  font-size: 20px;
  text-transform: none;
  padding-right: 50px;
  padding-left: 50px;
}

.btnThirdSectionMobile {
  letter-spacing: 1px;
  margin-top: 50px;
  font-size: 14px;
  text-transform: none;
  padding-right: 50px;
  padding-left: 50px;
}

.secondTitlePresentation {
  padding-top: 75px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.subTitlePresentation {
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
  padding-bottom: 75px;
}

.secondTitlePresentationMobile {
  font-size: 22px;
  padding-top: 75px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.subTitlePresentationMobile {
  text-align: center;
  font-size: 16px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
  padding: 10px 10px 50px;
}

.subTitleFourSectionMobile {
  color: #57939E;
  font-family: Comfortaa, sans-serif;
  font-size: 16px;
  padding-top: 40px;
}

.textFourSectionMobile {
  color: #808080;
  padding-right: 15px;
  padding-bottom: 80px;
  font-size: 14px;
}

.subTitleFourSection {
  color: #57939E;
  font-family: Comfortaa, sans-serif;
}

.fourSectionLeft {
  color: #808080;
  text-align: right;
}

.fourSectionLeftMobile {
  padding-left: 5px;
  color: #808080;
}

.fourSectionRight {
  color: #808080;
  text-align: left;
}

.textFourSectionLeft {
  padding-left: 300px;
}

.textFourSectionRight {
  padding-right: 300px;
}

.imgMobile {
  margin-right: 250px;
}

.lastSection {
  text-align: center;
  color: #808080;
  font-size: 16px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 50px;
}

.lastSectionMobile {
  text-align: center;
  color: #808080;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 50px;
}


</style>
