<template>
  <div>
    <PresentationVitaeComp v-bind:mobile="mobile" />
  </div>
</template>

<script>
import PresentationVitaeComp from '@/components/desktop/vitaeSportSante/PresentationVitaeComp'

  export default {
    name: 'Missions',
    props: ['mobile'],
    components: {
      PresentationVitaeComp
    },
  }
</script>
